<template>
  <div class="wrapper static-article">
    <row>
      <column
        xs="12"
        md="2">
      </column>
      <column
        xs="12"
        md="8">
        <h1 class="ff-flama fs-xxl fw-light tt-uppercase mb-5">
          About us
        </h1>
        <div class="article-body clearfix mb-3">
          <h2 class="mt-0">Unrivalled renewable energy news</h2>
          <p>
            Recharge is the world’s leading business intelligence source for the renewable energy industries. We provide award-winning international coverage of breaking news, in-depth features and analysis across the wind and solar sectors.
            Learn about key energy issues as they happen and get industry insight from our experts.<br>
            <a href="https://www.rechargenews.com/subscription/">Become a Recharge subscriber today</a>.
          </p>
        </div>
      </column>
    </row>
  </div>
</template>

<script>
import { Page } from 'global-components';
export default {
  name: 'aboutus-page',
  extends: Page
};
</script>
